@import "theme";

@font-face {
  font-family: 'OpenSansReg';
  src: url('../fonts/OpenSans-Regular.ttf');
}

.app {
  width: 100vw;
  height: 100vh;
}

.com-bridge-hidden-iframe {
  display: none;
}

.box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.75) !important;
}

.logo {
  align-self: center;
  div {
    @extend .box-shadow;
    }
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  -ms-user-select: none;
}

.primary.ant-layout {
  background-color: $primary-color;
  height: 100%;
  > main {
    display: flex;
  }
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.wa {
  width: auto !important;
}

/* POSITIONING */
.mt5px {
  margin-top: 5px;
}

.vl-mb0 {
  margin-bottom: 0 !important;
}

.vl-mt0 {
  margin-top: 0 !important;
}

.vl-ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt14 {
  padding-top: 20px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.dib {
  display:inline-block;
}

/* COMPONENTS */
.buttons {
  margin-top: 40px !important;
}

.vl-btn {
  font-size: 16px !important;
  font-weight: 100 !important;
  cursor: pointer;
  width: 125px;
  border-radius: 2px;
}

.vl-btn-text {
  margin: 5px 10px;
  padding: 5px;
  text-align: center;
}

.vl-btn-red {
  background-color: $red-color !important;
  color: #FFFFFF !important;
  border-color: $red-color !important;

  &:hover {
    background-color: $dark-red-color !important;
    border-color: $dark-red-color !important;
  }
}

.vl-btn-green {
  background-color: $green-color !important;
  color: #FFFFFF !important;
  border-color: $green-color !important;

  &:hover {
    background-color: $light-green-color !important;
    border-color: $light-green-color !important;
  }
}

.leave-session-btn {
  z-index: 1;
  width: 140px;
  top: 45px;
  right: 45px;
}

.btn-second {
  margin: 0 0 0 20px;
  width: 225px !important;
}

.vl-btn-blue {
  background-color: $label-color !important;
  color: #FFFFFF !important;
  border-color: $label-color !important;
  height: 40px !important;
  &.ant-btn-sm {
    height: 32px !important;
  }

  &:hover {
    background-color: $label-hover !important;
    border-color: $label-hover !important;
  }
}

.btn-grey {
  background-color: $grey-color !important;
  color: $grey-color-darker !important;
  border-color: $grey-color !important;
  height: 40px !important;

  &:hover {
    background-color: $grey-hover !important;
    border-color: $grey-hover !important;
  }
}

.disabled {
  cursor: not-allowed;
}

.main-spin {
  @extend .full-size;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-color;
}

.main-modal .ant-modal-header {
  border-radius: 25px 25px 0 0;
}

.main-modal .ant-modal-content {
  border-radius: 25px;
}

.main-modal .ant-form-item-control-input-content {
  width: 100%;
}

.caller .ant-col {
  text-align: center;
}

.incoming-call .ant-notification-notice-btn {
  float: left;
}

.caller h1 {
  color: $grey-color;
  margin-bottom: 50px;
  font-size: 22px;
}

.favorites-tabs {
  .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
}

.video-overlay-invite {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: absolute;
  z-index: 3;
}

.circle {
  background-color: #fff;
  border: 1px solid $grey-color;
  height: 72px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 72px;
}

.presence-badge {
  margin: 0;
  width: 15px;
  align-self: flex-start;

  .available {
    width: 12px;
    height: 12px;
    border-radius: 10px;
    font-size: 10px;
    font-weight: bold;
    color: $green-color;
    line-height: 20px;
    text-align: center;
    background: $green-color;
  }
  &:hover {
    opacity: 0.4;
    filter: alpha(opacity=40);
  }

  .dnd {
    @extend .available;
    background: $red-color;
  }

  .oncall {
    @extend .available;
    background: #1990cf;
  }

  .offline {
    @extend .available;
    background: $grey-color-dark;
  }
}

$status-width: 15px;

.search-profile {
  padding: 0 15px 0 10px !important;

  .ant-badge-status-text {
    color: $grey-color;
  }
  .ant-badge-status-processing {
    width: $status-width;
    height: $status-width;
  }
  .ant-badge-status-default {
    @extend .ant-badge-status-processing;
  }
}

.profile-available {
  .ant-badge-status-default {
    background-color: $green-color;
  }
}

.profile-oncall {
  .ant-badge-status-default {
    background-color: #1990cf;
  }
}

.profile-dnd {
  .ant-badge-status-processing {
    background-color: $red-color;
  }
}

.profile-offline {
  .ant-badge-status-default {
    background-color: #7D7D7D;
  }
}


.circle-inner {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  font-size: 30px;
  font-weight: bold;
  color: black;
  line-height: 60px;
  text-align: center;
  margin-left: 5px;
  margin-top: 5px;
  background: $grey-color;
}

.circle-image {
  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
  border-bottom-right-radius: 50% 50%;
  border-bottom-left-radius: 50% 50%;
}

.full-size {
  @extend .full-height;
  @extend .full-width;
}

.left-sider {
  border-width: 0 1px 0 0;
  border-color: $grey-color;
  border-style: solid;
}

.video-menu-content {
  width: 445px;
  height: 670px;
  margin-left: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.video-menu-content .call-button img {
  width: 75px;
  height: 75px;
}

.video-menu-content h3 {
  color: $primary-color;
}

.video-menu-content .ant-divider {
  background: $primary-color;
  height: 2px;
  margin: 0px 0 10px 0;
  opacity: 0.5;
}

.selfview-video {
  height: calc(100vh - 200px); // 100vh - (header + footer)
  width: 100vw;
}

.bedside-container {
  border: 0;
  background-color: black;
  width: 1270px;
  height: 800px;
}

.bgr-black {
  background-color: black;
}

.bedside-container-fullscreen {
  border: 0;
  background-color: black;
  width: 100vw;
  height: 100vh;
}

.iobserver-container-fullscreen {
  border: 0;
  background-color: black;
  width: 100vw;
  height: calc(100vh - 70px);
}

.video-menu-button {
  width: 50px;
  height: 50px;
}

.video-modal {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: $grey-color;
  padding: 5px;
  background: white;
}

.video-modal .call-button {
  padding: 15px;
}

.video-modal .close-button {
  display: flex;
  justify-content: flex-end;
}

.video-modal .search-content .ant-list-items {
  width: 430px;
}

.video-modal .external-form {
  padding: 10px;
}

.search-content {
  @extend .full-width;
}

.search-content .ant-form-item {
  justify-content: center;
}

.search-content .ant-divider {
  background: $primary-color;
  height: 2px;
  opacity: 0.5;
}

.search-content .ant-form-item-control {
  width: 500px;
}

.search-content .ant-list-items {
  width: 560px;
  margin: auto;
}

.vl-profile-collapse-panel span {
  margin: 0;
  padding-top: 0;
  color: $grey-color;
  font-size: 15px;
  flex-grow: 2;
}

.navigation-link span {
  margin: 0;
  padding-top: 0;
  color: $label-color;
  font-size: 16px;
  flex-grow: 2;
}

$bounceY : -5px;
$bounceTime : 1s;

.vl-profiles-list .vl-list-item {
  border-width: 0;
  border-color: transparent;
  border-bottom: none !important;

  &:nth-child(odd) {
    background-color: $field-color;
  }

  &:nth-child(even) {
    background-color: transparent;
  }
}

.vl-profiles-list-item {
  @extend .full-width;
  display: flex;
  align-items: start;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;

  .ant-collapse-content-box {
    padding-bottom: 20px !important;
  }

  .ant-collapse {
    width: 100%;
    background: transparent;
  }

  .icon {
    margin-top: 10px;
  }

  .icon img:hover {
    cursor: pointer;
    animation: bounce $bounceTime infinite; //other browsers
    -webkit-animation: bounce $bounceTime infinite; //webkit browsers
    -moz-animation: bounce $bounceTime infinite; //firefox
    -o-animation: bounce $bounceTime infinite; //opera
  }

  .sound-wave-icon {
    margin-left: 10px;
    width: 24px;
  }

  .ant-badge, .ant-collapse-header, .ant-collapse-arrow, .vl-avatar {
    line-height: 50px !important;
  }

  .vl-avatar {
    margin-right: 15px;
  }
  .ant-badge-status-text {
    display: none;
  }
  .ant-badge-status-dot {
    width: 16px !important;
    height: 16px !important;
    margin: 17px 0;
  }

  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
    cursor : pointer;
  }

  .ant-collapse-item.vl-profile-collapse-panel>.ant-collapse-header {
    display: block;
    padding: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    padding-top: 0;
  }
}

.favorite-star-wrapper {
  margin: 14px 0;
}

.call-button-wrapper {
  margin: 9px 0;
}

.vl-chat-main {
  overflow: auto;
}

// CHAT
.vl-chat-footer {
  position: absolute;
  bottom: 40px;
}

.vl-chat-form {
  margin: 0 40px !important;
  justify-content: space-between;

  > .ant-form-item {
    margin: 20px 0;
  }

  .ant-form-item-label > label{
    color: $grey-color;
    font-size: 16px;
  }

  .vl-select-field {
    margin-left: 15px;
  }

  input#message {
    width: 308px;
  }

}

.vl-chat-list .vl-list-item {
  border-width: 0;
  border-color: transparent;
  border-bottom: none !important;
  justify-content: flex-start;
  margin: 25px 0 !important;

  .vl-avatar {
    align-self: flex-start;
  }

  //&:nth-child(even){
    .vl-chat-list-item {
      background-color: #FFFFFF;
    }
    .vl-chat-name {
      left: 0;
    }
  //}

  &.mine {
    flex-direction: row-reverse;

    .vl-chat-list-item {
      background-color: $field-color;
      color: #FFFFFF;
    }

    .vl-chat-name {
      right: 0;
    }
  }
}

.vl-chat-name {
  top: -22px;
  color: $grey-color;
  position: absolute;
  font-size: 10px;
}

.vl-chat-list-item {
  position: relative;
  display: flex;
  margin: 0 15px;
  padding: 10px 15px;
  align-items: start;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  word-break: break-word;
  border-radius: 5px;
  width: auto;
  min-width: 35%;
  max-width: 70%;
}


// IPC Override Modal
.ipcOverrideForm {
  margin: 0 40px !important;

  .vl-select-field {
    width: 100%;
    margin-left: 0;
  }

  textarea {
    background-color: $primary-input-color;
    border: none;
    color: $grey-color !important;
    height: 130px !important;
  }

  label {
    color: $grey-color !important;
  }
}

//CSS3 Bounce Animation
@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY($bounceY);
  }
}
@-moz-keyframes bounce {
  0%,
  100% {
    -moz-transform: translateY(0);
  }
  50% {
    -moz-transform: translateY($bounceY);
  }
}
@-o-keyframes bounce {
  0%,
  100% {
    -o-transform: translateY(0);
  }
  50% {
    -o-transform: translateY($bounceY);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY($bounceY);
  }
}

.vl-profile-collapse-panel {
  border: none !important;
}

.tar {
  text-align: right;
}

.tac {
  text-align: center;
}

.vl-header {
  min-height: 90px;
  position: relative;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  user-select: none;
  color: $label-color;
  background-color: $primary-color;
  font-size: 20px;
}

.vl-header a{
  color:  $label-color;
}

.header-section-left {
  align-self: center;
}

.header-section-center {
  align-self: center;
  margin: 0 20px;
  @media (max-width: 1024px) {
    flex-grow: 1;
    text-align: center;
  }
}

.vl-header .vl-right-section{
  display: flex;
  align-items: center;
}

.vl-action-image {
  cursor: pointer;
}

.vl-header-logo {
  align-items: center;
  padding-left: 40px;
}
.info {
  padding: 0 0 0 50px;
  font-size: 28px;
  display: inline-block;
}
.vl-incall-info {
  font-size: 20px;
  align-items: center;
}

.user-name {
  padding: 0 0 0 10px;
  font-size: 16px;
}

.arrow-collapse {
  width: 10px;
  margin: 0 7px;
  &:hover {
    opacity: 0.4;
    filter: invert(37%) sepia(99%) saturate(1606%) hue-rotate(172deg) brightness(93%) contrast(102%);
  }
}

.vl-center-img {
  height: 26px;
  margin-left: 30px;
  &:hover {
    opacity: 0.4;
    filter: alpha(opacity=40);
  }
}

.vl-timer {
  font-size: 16px;
  color: $grey-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-star {
  &:hover {
    opacity: 0.4;
    filter: alpha(opacity=40);
  }
}

.right-img {
  height: 30px;
  margin-left: 30px;
  align-items: center;
}

.top-header {
  @extend .full-width;
  height: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.profile-image:hover {
  opacity: 0.4;
  filter: alpha(opacity=40); /* msie */
}

.top-header-left {
  height: 60px;
  align-self: flex-start;
  padding-top: 3px;
  padding-left: 30px;
}

.top-header-right {
  @extend .full-height;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  @media (min-width: 1024px) {
    padding-right: 40px;
  }
}

.top-header-right span {
  cursor: pointer;
}

.video-overlay-invite .top-header h2 {
  flex-grow: 2;
  margin: auto;
  color: white;
}

.video-overlay-invite .top-header p {
  color: white;
  margin: auto;
  font-size: 18px;
}

.main-buttons {
  @extend .vl-align-center;
  width: 100%;
  margin-bottom: 50px;
  background: linear-gradient(to bottom, #ffffff, #eeeeee);
}

.flex-start {
  align-self: flex-start;
}

.flex-end {
  align-self: flex-end;
}

.left-content {
  @extend .full-height;
  @extend .vl-align-center;
  border-right: 1px;
  border-color: $grey-color;
}

.vl-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-form {
  @extend .full-size;
  padding: 10px;
}

.external-form {
  padding: 0 200px 0 200px;
}

.edit-form .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #4f8bc7;
}

.ant-btn-link span {
  text-decoration: underline;
}

span.link {
  text-decoration: none;
  color: $primary-color;
  cursor: pointer;
}

.call-button {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.call-button p {
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: normal;
}

.call-button .disabled {
  color: $grey-color;
}

.call-button img {
  width: 125px;
  height: 125px;
}

.profile {
  @extend .full-height;
}

.devices-form {
  @extend .full-width;
}

.about h2 {
  padding: 20px 0 0;
  margin: 0;
  font-size: 16px;
  text-align: center;
  color: $grey-color;
  word-break: keep-all;
}

.profile img {
  margin-bottom: 30px;
}

.grey-out:hover {
  filter: $label-hover-filter; /* msie */
}

.profile h2 {
  text-align: center;
  font-size: 24px;
  color: $grey-color;
}

.profile h1 {
  font-size: 16px;
  color: $grey-color;
}

.profile .mb-40 {
  font-size: 16px;
  color: $grey-color;
  margin-bottom: 40px;
}

#modal-label {
  color: $label-color;
  font-size: 16px;
  text-align: left;
}
.profile .profile-label {
  @extend #modal-label;
  padding-left: 40px;
}

.profile .settings-label {
  @extend #modal-label;
  padding-left: 20px;
}

.shrtcut .shortcuts-label {
  color: $grey-color;
  font-size: 16px;
  text-align: left;
}

.profile .edit-profile-label {
  color: $label-color;
  font-size: 12px;
  float: left;
}

.profile p {
  font-size: 16px;
  width: 250px;
  text-align: left;
}

.edit-profile {
  @extend .profile;
}

.edit-profile img {
  margin-bottom: 30px;
  cursor: pointer;
}


.profile-pic-combined{
  height:100px;
  width:100px;
  margin-bottom: 30px;
}
.profile-pic-combined img{
  display:block;
  height:100px;
  width:100px;
  margin:0;
  padding:0;
  position:absolute;
}

.deleteProfile {
  border-radius: 50%;
  position:absolute;
  left:10px;
  top: 80px;
  height:20px;
  width:20px;
  background-color: $field-color;
}

.editProfile {
  @extend .deleteProfile;
  left:72px;
}

#deleteProfile, #editProfile {
  height:20px;
  width:20px;
  padding: 5px;
  &:hover{
    filter: $label-hover-filter; /* msie */
  }
}

.modal-content {
  display: flex;
  align-items:center;
}

.modal-buttons {
  padding: 30px 0 0;
  text-align: center;
}

.modal-info {
  font-size: 16px;
  padding: 0 10px;
}

.shrtcut .shortcuts {
  text-align: right;
}

.shrtcut .and {
  font-size: 12px;
  padding: 0 10px;
}

.ant-drawer-wrapper-body p {
  color: white;
  font-size: 30px;
  padding-left: 40px;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}

/******** new addition for new GUI *****/

@mixin panels {
  background-color: $primary-panel-color;
  box-shadow: 3px 3px 8px $panels-boxshadow;
  border-radius: 5px;
}

/* Collapse */
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.f-sans {
  font-family: $fontSansReg;
  font-style: normal;
}
.vl-f-14 {
  @extend .f-sans;
  font-size: 14px;
}
.vl-f-16 {
  @extend .f-sans;
  font-size: 16px;
}
.f-18 {
  @extend .f-sans;
  font-size: 18px;
}
.f-20 {
  @extend .f-sans;
  font-size: 20px;
}

.w-100 {
  width: 110px;
}

.w-100.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
    font-size: 18px;
}

.vl-collapse {
  display:inline-block;
  box-sizing:border-box;
  text-align:left;
}

.vl-collapse .vl-property{
  @extend .vl-collapse;
  color: $label-color;
  width: 86px;
  vertical-align: top;
}

.vl-collapse .property-short{
  @extend .vl-collapse;
  color: $label-color;
  width: 40%;
}

.vl-collapse .vl-value{
  @extend .vl-collapse;
  color: $grey-color;
  padding-left:10px;
  width:60%;
}

.vl-value-text {
  width: 202px;
  overflow: hidden;
  color: $grey-color;
}

span.filled.anticon.anticon-star:hover {
  color: red !important;
}

span.outlined.anticon.anticon-star:hover {
  color: $label-color !important;
}

.ant-badge-status-processing {
  width: 12px;
  height: 12px;
}

.top-header.ant-layout-header {
  @extend .full-width;
  padding-right: 20px;
  height: 70px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 2px 2px #000000;
  background: $primary-color;
}

.vl-profile-collapse-panel.vl-header {
  font-size: 18px;
}

.top-header-right .ant-collapse-borderless {
  background-color: transparent;
  border: 0;
  margin-top: 7px;
}

/******************** Footer ***********************/

@keyframes fade {
  0% { opacity: 1 }
 90% { opacity: 1 }
 100% { opacity: 0 }
}

.vl-app-footer.ant-layout-footer {
  @extend .full-width;
  padding: 24px 0;
  position: relative;
  width: 100vw;
  bottom: 0;
  background-color: $primary-color;
}

//TODO Do we need this? Ony during the call?
// .app-footer.ant-layout-footer:hover {
//   opacity: 1;
// }
// .app-footer.ant-layout-footer:not( :hover ){
//   opacity: 0;
//   animation: fade ease-in-out 20s;
// }

%footer-icon-div {
  height: 50px;
  width: 50px;
  display: inline-block;
  margin: 0 10px 0 10px;
  color: transparent;
  text-align: center;
  font-weight: normal;
  font-family: $fontSansReg;
  font-size: 12px;
}

.vl-footer-img {
  display: block;
  padding: 0;
  width: 50px;
  height: 50px;
  margin: 0;
  border: 1px solid transparent;
}

.vl-app-footer, .vl-header {
  user-select: none;

  .vl-app-footer-row {
    display: flex;
    justify-content: space-between;
  }

  .vl-footer-icon-off {
    @extend %footer-icon-div;
    &:hover {
      color: $grey-color;
    }
  }
  .ant-badge {
    .ant-badge-count {
      box-shadow: none;
      top: 5px;
      right: 14px;
    }
  }

  .vl-footer-icon-on {
    @extend %footer-icon-div;
      filter: invert(51%) sepia(81%) saturate(385%) hue-rotate(73deg) brightness(100%) contrast(89%);
      &:hover {
        color: $grey-color;
        filter: invert(51%) sepia(81%) saturate(385%) hue-rotate(73deg) brightness(100%) contrast(89%);
      }
  }
  .vl-footer-icon-off-red {
    @extend %footer-icon-div;
    &:hover {
      color: $grey-color;
    }
  }
  .vl-footer-icon-on-red {
    @extend %footer-icon-div;
    filter: invert(53%) sepia(91%) saturate(7484%) hue-rotate(350deg) brightness(88%) contrast(133%);
    &:hover {
      color: $grey-color;
      filter: invert(53%) sepia(91%) saturate(7484%) hue-rotate(350deg) brightness(88%) contrast(133%);
    }
  }

  .vl-left-section {
    display: flex;
    justify-content: center;
  }

  .vl-middle-section {
    display: flex;
    justify-content: center;
  }

  .vl-right-section {
    display: flex;
    justify-content: end;

    .eko-spin-icon {
      position: absolute;
      left: 10px;
    }
  }

  .w-90 {
    width: 100px;
    text-align: center;
    overflow: visible;
    margin-left: -25px;
  }

  .vl-mr-50 {
    margin-right: 50px;
  }

  .left {
    float: left;
  }

  .right {
    float: right;
  }
}

.vl-invite-scroll {
  /*** Firefox  ***/
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: $grey-color-dark;   /* scroll thumb and track */

  margin-top: 5px;
  margin-right: 20px;
  cursor: pointer;
  overflow-y: auto;

  li {
    list-style-type: none;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $field-color;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $grey-color-dark;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $grey-color-dark;
  }
}

.ml-7 {
  margin-left: 5px;
}

.vl-poh-sign {
  position: fixed;
  bottom: 120px;
  left: 43%;
  background: $primary-color;
  width: 275px;
  height: 60px;
  color: $red-color;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  line-height: 60px;
}

.video-off {
  width: 410px;
  height: 250px;
  line-height: 250px;
  background-color: $field-color;
  color: $label-color;
  text-align: center;
  font-size: 18px;
}

.backgroundImageHolder {
  padding-left: 34px;
  padding-right: 20px !important;
  height: 280px;
}

.backgroundImage {
  margin: 3px;
  background: none;
  width: 118px;
  height: 68px;
  display: flex;
  float: left;
  vertical-align: middle;
    cursor: pointer;
}

.fixed-h300 {
  height: 300px;
}

.fixed-h300.loaded {
  height: 300px;
}

.backgroundImageSelected {
  border: 2px solid #0096D7;
}

.backgroundImage img {
  border: none !important;
  cursor: pointer;
  margin: 2px;
  background: none;
  width: 110px;
  height: 60px;
  border: none;
}

.no-access-msg {
  height: 100%;
  font-size: 18px;
  text-align: center;
  background-color: #083659;
  color: #0092D7;
  p {
    margin-top: 5%;
  }
}
