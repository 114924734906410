html {
  overflow: hidden;
}

body {
  margin: 0;
  overflow: scroll;
  overflow-y: auto !important;
}

.demo-root {
  display: flex;
  flex-direction: column;
}

.demo-buttons {
  margin: 10px 0 0 14px;
  button {
    margin-right: 5px;
    padding: 5px !important;
    background: inherit !important;
    border: 1px solid black !important;
  }
}

.demo-input {
  input, select {
    margin: 5px;
  }
  margin: 10px 0 0 10px;
  display: flex;
  flex-direction: column;
}

.zoom-demo-component {
  margin: 0;
  width: 100vw;
  height: 100vh;
}

.pexip-demo-component {
  height: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  background-color: black;
}

.vl-zoomContainer > div {
  transform: translate(0px, 0px) !important;
}

.vl-zoomContainer * {
  box-shadow: none !important;
}

.vl-zoomContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #242424;
}
