@import "theme";

.vl-bars-wrapper {
  width: 100%;
}

.vl-bar {
  width: calc(10% - 10px);
  height: 5px;
  display: inline-block;
  margin: 5px;
  background-color: $grey-color;
  
}