// COLORS
$primary-color: #083659;
$text-color: $primary-color;
$field-color: #0A1F33;
$label-color: #0092D7;
$label-color-filter: invert(37%) sepia(99%) saturate(1606%) hue-rotate(172deg) brightness(93%) contrast(102%);
$label-hover: #49B2FF;
$label-hover-filter: invert(49%) sepia(92%) saturate(1133%) hue-rotate(188deg) brightness(111%) contrast(101%);
$selected-background: #0091D6;
$green-color: #44B449;
$light-green-color: #44D249;
$white: #FFFFFF;
$grey-color: #C8C8C8;
$grey-hover: #E6E6E6;
$grey-color-dark: #7D7D7D;
$grey-color-darker: #646464;
$red-color: #FF0000;
$dark-red-color: #DC0000;
$panels-boxshadow: rgba(0, 0, 0, 0.5);
$primary-panel-color: rgba(12, 42, 68, .9); // #0C2A44
$primary-panel-color-no-transparency: #0C2A44;
$primary-input-color: rgba(10, 31, 51, 0.9); // #0A1F33

// antd vars
$border-radius-base: 4px; // major border radius
$layout-header-background: $primary-color;

// other vars
$fontSansReg: "OpenSansReg";
$hover-filter: invert(54%) sepia(100%) saturate(1009%) hue-rotate(181deg) brightness(103%) contrast(102%);
