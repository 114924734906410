@import "theme";
@import "app";

/*************** base MovablePanel class ***********/

.vl-movablePanelHeader {
  line-height: 55px;
  height: 55px;
  cursor: move;
}

.vl-movableMod {
  @extend .box-shadow;
  position: fixed;
  z-index: 2;
  background: rgba($primary-panel-color, 0.9);
  border-radius: 10px;
  padding-bottom: 40px;

  .ant-divider-horizontal {
    margin-bottom: 20px !important;
  }
}

.vl-movableMod .ant-divider {
  border-width: 1px;
  margin-top: 0;
  border-color: $grey-color-dark;
}

.modal {
  position: absolute;
  color: $label-color;
  font-size: 24px;
}

.vl-modalTitle {
  @extend .modal;
  top: 0;
  left: 40px;
}

.vl-modalDivider {
  width: calc(100% - 80px);
  margin: auto;
}

.vl-modalExpend {
  @extend .modal;
  top: 19px;
  right: 80px;
  cursor: pointer;
  z-index: 3;
}

.vl-modalReload {
  @extend .vl-modalExpend;
  top: 16px;
  right: 120px;
}

.vl-modalDownload {
  @extend .vl-modalExpend;
  font-size: 23px;
  top: 16px;
  right: 160px;
}

.vl-modalClose {
  @extend .vl-modalExpend;
  top: 20px;
  right: 40px;

  &:hover {
    filter: $hover-filter;
  }
}

span.remove-fav>img {
  filter: invert(64%) sepia(99%) saturate(0%) hue-rotate(144deg) brightness(106%) contrast(90%);
  height: 13px;
  margin-right: 7px;
  padding-bottom: 3px;

  &:hover {
    filter: $hover-filter;
  }
}

.modContent {
  @extend .modal;
  width: 100%;
  height: 100%;
}

#video-screenshot {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.vl-screenshot {
  padding: 0px 40px 40px 40px;
  position: absolute;
  top: 90px;
  bottom: 0;
  left: 0;
  right: 0;
}

.vl-loading {
  color: $label-color;
  padding: 0px 40px 40px 40px;
  font-size: 18px;
}

/*********************** Settings ******************/
.vl-movable-panel-content {
  padding: 20px 40px 40px;
}

.vl-settings-row {
  width: 100%;
  margin: 15px 0;
}

.vl-setting-img {
  width: 30px;
  height: 30px;
  padding-top: 3px;
}

.vl-setting-img2 {
  width: 25px;
  height: 25px;
  padding-top: 3px;
}

.vl-setting-img3 {
  @extend .vl-setting-img2;
  margin: 5px 0 0 20px;
}

.vl-setting-tooltip {
  font-size: 12px;
  color: $grey-color;
  margin-top: 5px;
  width: 65px;
  text-align: center;
}

.vl-setting-select {
  width: 330px;
  outline: none;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    font-family: $fontSansReg;
    font-size: 16px;
    text-indent: 3px;
    color: $label-color;
    background-color: $primary-input-color;
    border: 1px solid $primary-panel-color;
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 40px; //element
    align-items: center; //vertical
    outline: none !important;

    &:focus {
      outline: none !important;
    }

    max-width: 330px;
    min-width: 330px;
  }

  .ant-select-arrow {
    color: $grey-color;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: white;
    color: $label-color;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: white;
    color: $label-color;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $primary-panel-color;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $primary-panel-color;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: $label-color;
  }

  .ant-select-arrow {
    color: "#C8C8C8";
    float: left;
  }
}

.vl-setting-slider.ant-slider {
  z-index: 8;

  .ant-slider-rail {
    background-color: $primary-input-color;
  }

  .ant-slider-track {
    background-color: $selected-background;
  }

  .ant-slider-handle {
    background-color: $selected-background;
    border: solid 2px $selected-background;
  }
}

.vl-setting-sliderV.ant-slider.ant-slider-vertical {
  padding: 0 20px;
  z-index: 8;

  .ant-slider-rail {
    background-color: $primary-input-color;
  }

  .ant-slider-track {
    background-color: $selected-background;
  }

  .ant-slider-handle {
    background-color: $selected-background;
    border: solid 2px $selected-background;
  }
}

.vl-setting-sliderV.ant-slider.ant-slider-disabled.ant-slider-vertical .ant-slider-handle {
  background-color: $primary-input-color;
}

.av-settings-footer {
  margin: 20px 40px 0;

  .vl-btn {
    width: 125px;
    height: 40px;
  }
}

.vl-btn.vl-settings-button {
  padding: 0;
  font-size: 10px !important;
  width: 65px !important;
  height: 20px !important;
}

.vl-setting-left {
  display: inline-block;
  width: 220px;
  height: 280px;
  padding-left: 15px;
}

.vl-setting-right {
  @extend .vl-setting-left;
  border-left: 1px solid $grey-color-dark;
  padding-left: 0;
}

.vl-setting-caption {
  margin-top: 20px;
  font-size: 16px;
  color: $selected-background;
  height: 40px;
  text-align: center;
}

.vl-div-slider {
  height: 150px;
}

.vl-div-slider-left {
  height: 150px;
  margin-left: 20px;
}

/*********  Care Device style **************/

.care-divider {
  margin: 0 30px;
  border-top: 3px solid $grey-color-dark;
}

.vl-care-div-left {
  display: inline-block;
  width: 170px;
}

.vl-controller-block {
  padding: 0 20px 0 40px;
  width: 160px;
}

.vl-controller-img {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.vl-home-img {
  position: absolute;
  top: 39.3%;
  left: 19.7%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  &:hover {
    filter: $hover-filter;
  }
}

.vl-zoom-block {
  padding: 15px 5px 0 32px;
  margin: 15px 0;
  width: 220px;
}

.vl-zoom-out {
  padding-left: 15px;
  cursor: pointer;
  height: 35px;
}

.vl-zoom-in {
  padding-left: 15px;
  cursor: pointer;
  height: 35px;
}

.vl-list-scroll {
  /*** Firefox  ***/
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: $grey-color-dark;
  /* scroll thumb and track */

  margin-top: 5px;
  cursor: pointer;
  overflow-y: scroll;
  height: 160px;
  width: 270px;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $field-color;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $grey-color-dark;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $grey-color-dark;
  }
}

.vl-refresh-block {
  padding: 5px 5px 0 35px;
  width: 150px;
  color: $label-color;
}

.vl-refresh-set {
  padding-left: 50px;
  padding-top: 5px;
  width: 150px;
  color: $label-color;
}

.vl-set {
  font-size: 14px;
  cursor: pointer;

  &:hover {
    filter: $hover-filter;
  }
}

.vl-refresh {
  float: left;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    filter: $hover-filter;
  }
}

.vl-reboot {
  float: right;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    filter: $hover-filter;
  }
}

.vl-care-right {
  float: right;
  width: 240px;
  margin: 0 30px 20px 0;
}

.vl-input-pin {
  font-size: 16px;

  input.ant-input {
    background-color: $field-color;
    border-color: transparent;
    width: 220px;
    height: 40px;
    color: $grey-color-dark; //input text color!
  }

  //input placeholder color!
  input.ant-input::placeholder {
    color: $grey-color-dark;
  }
}

li.ant-list-item.vl-pin-list-item {
  padding: 5px 0;
  margin-right: 10px;
  height: 40px;
  color: $grey-color;
  font-size: 16px;
  border-width: 0;
  border-color: transparent;
}

li.ant-list-item.vl-pin-list-item:nth-child(odd) {
  background-color: transparent;
}

li.ant-list-item.vl-pin-list-item:nth-child(even) {
  background-color: $field-color;
}

.pin-line {
  border-width: 0;
  border-color: transparent;
}

.vl-pinName {
  width: 120px;
  padding-left: 15px;
  display: inline-block;
  color: $grey-color;
}

.vl-pinImg {
  height: 20px;
  display: inline-block;

  &:hover {
    filter: $hover-filter;
  }
}

.vl-pinImgRight {
  @extend .vl-pinImg;
  margin-right: 15px;
}