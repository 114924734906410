@import "theme";
@import "app";

$status-width: 12px;
$bounceTime : 1s;

/****************    Header Multi Call Styles  ********************/
.multi-call-header {
  margin-top: 90px;
  padding: 20px 0px 20px;
  background-color: #FFAF00
}

#multi-call-button {
  margin-left: 25px;
  width: 200px;
}

.text-header-milti-call {
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  text-align: center;
}

/****************    label styles   *******************************/
.caption {
  color: $label-color;
  font-family: $fontSansReg;
  font-style: normal;
}

.f-24 {
  font-size: 24px;
}

.f-22 {
  font-size: 22px;
  margin: 10px 0 10px 40px;
}

/****************    basic styles   *******************************/
body {
  font-family: $fontSansReg, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-size: 16px;
}


/**************** layout *******************************/

.ant-layout-sider {
  background-color: transparent !important;
}

.ant-layout-sider-children {
  background-color: $primary-color;
}

/****************    Select drop-down appearance   *******************************/
.vl-select-field,
.edit-profile-custom {
  margin-left: 40px;
  width: 225px;
  outline: none;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    font-family: $fontSansReg;
    font-size: 16px;
    text-indent: 4px;
    color: $grey-color-dark;
    background-color: $primary-input-color  !important;
    border: none;
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 40px; //element
    align-items: center; //vertical
    outline: none !important;

    &:focus {
      outline: none !important;
    }
  }

  .ant-select-selection-placeholder {
    color: $grey-color-dark;
  }

  .ant-select-arrow {
    color: $grey-color;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent;
  }

  .ant-select-item {
    color: $grey-color;
    font-size: 16px;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: transparent !important;
    color: $label-color  !important;
    font-size: 16px;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $primary-input-color;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $primary-input-color;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: $label-color;
  }

  .ant-select-dropdown {
    @extend .box-shadow;
    background-color: $primary-panel-color-no-transparency;
    border-radius: 5px;
    color: $grey-color  !important;
    font-weight: normal;
    font-size: 16px;
    padding: 15px 8px;
  }
}

.vl-select-field-lgr {
  @extend .vl-select-field;
  width: 420px;
  margin-top: 10px;
}

.edit-profile-custom {
  @extend .vl-select-field;
  margin-left: 0;
  width: 100%;
}

.vl-grey {
  .vl-select-field .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    color: $grey-color-dark;
  }

  .edit-profile-custom .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    color: $grey-color-dark;
  }
}

.select-field-header {
  @extend .vl-select-field;
  margin-top: 10px;

  .ant-select-single:not(.ant-select-customize-input)>.ant-select-selector {
    font-size: 30px;
    background-color: transparent;
    border: transparent;
    width: 200px;
  }
}


/************************ input field  *****************/
.vl-input-guest {
  input.ant-input.ant-input-lg {
    background-color: $primary-input-color;
    border-color: transparent;
    width: 225px;
    margin-left: 40px;
    color: $grey-color-dark; //input text color!
  }

  //input placeholder color!
  input.ant-input.ant-input-lg::placeholder {
    color: $grey-color-dark;
  }

  .ant-form-item-explain {
    margin-left: 40px;
    font-size: 12px;

    .vl-help {
      height: 40px;
      padding: 5px 0 0 10px;
      color: #FF6666;
    }
  }
}

/************************ Search field  *****************/

.vl-search-field {
  input.ant-input.ant-input-lg {
    background-color: transparent;
  }
}

input-field {
  width: 100%;
  outline: none;
  background-color: $primary-input-color;
  border: none;
  height: 40px;
  font-family: $fontSansReg;
  font-style: normal;
  font-size: 16px;
  text-indent: 5px;
  color: $grey-color-dark;
}

input.ant-input.ant-input-lg.dispay-name-edit {
  @extend input-field
}

span.vl-search-field.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  @extend input-field
}

//input text color!
span.vl-search-field.ant-input-affix-wrapper.ant-input-affix-wrapper-lg>.ant-input {
  color: $grey-color-dark;
  background-color: transparent;
}

//input placeholder color!
span.vl-search-field.ant-input-affix-wrapper.ant-input-affix-wrapper-lg>.ant-input::placeholder {
  color: $grey-color-dark;
}


/***************  Rows and margins  ***********************/
.vl-h-60.ant-row {
  height: 60px;
  line-height: 60px;
}

.vl-warning-message {
  align-items: center;
  border: 1px solid #7D7D7D;
  border-radius: 5px;
  font-size: 16px;
  margin: 0 40px 20px 40px;
}

.vl-text-warning {
  color: #FFAF00;
  margin: 0 0px 0px 50px;
}

.vl-image-warning {
  color: #FFAF00;
  font-size: 24px;
  margin: 0 0px -40px 12px;
}


/***************** Checkbox  ************************/
.vl-cb-right {
  margin-bottom: 20px;
  margin-left: 40px;
}

.inner-panel-title {
  @extend .cb-label-active;
  @extend .f-24;
  margin-bottom: 10px;
  margin-left: 40px;
  margin-top: 10px;
}

.inner-panel-divider {
  margin: 0 40px 20px 40px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid $grey-color-dark;
}

div.cb-bedside.ant-row.ant-form-item {
  margin: 12px 0 0 32px;
}

.vl-bedside-star {
  margin: 3px 0 0 7px;
  font-family: $fontSansReg;
  font-style: normal;
  font-size: 16px;
  color: $grey-color;
  cursor: pointer;
}

.vl-part-text {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
}

.vl-part-text:hover {
  overflow: visible; 
  white-space: normal; 
}

.vl-part-button-wrap {
  display: flex;
  align-items: center;
}

.vl-part-button {
  margin: 0 0 0 17px;
  padding-top: 3px;
  line-height: 50px;

  img {
    height: 32px;
  }

  cursor:pointer;

  &:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
}

/*************** Checkbox ********************/
.vl-cb {
  .ant-checkbox {
    border: 1px solid $grey-color;
    border-radius: 3px;
    margin: 8px;
  }

  .ant-checkbox-inner {
    background-color: transparent;
    border: 0;
    width: 14px;
    height: 14px;
  }

  .ant-checkbox-inner:checked {
    background-color: $label-color;
    border-color: $label-color;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $label-color;
    border-color: $label-color;
  }

  .ant-checkbox+span {
    padding: 0;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: $primary-color;
  }

  .ant-checkbox-checked::after {
    border-color: $primary-color;
  }
}

.profile-spec.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: $grey-color-dark;
  background-color: $field-color;
  border: none;
}

.profile-spec {
  width: 100%;
  min-width: 200px;

  .ant-select-arrow {
    color: $grey-color;
  }
}

.profile-spec.ant-select-multiple .ant-select-selection-item {
  background-color: $field-color;
  border: none;
  font-size: 16px;
}

.vl-cb-label {
  color: $grey-color;
  font-family: $fontSansReg;
  font-style: normal;
}

.cb-label-active {
  @extend .vl-cb-label;
  color: $label-color;
}

/********** image with text style and hover together ************/
.fav-section {
  padding-top: 4px;
  text-align: right;
  margin-right: 40px;

  .ant-btn-link {
    padding: 0;
  }
}

.fav-layer {
  @extend .vl-cb-label;
}

.fav-section:hover img,
.fav-section:hover .fav-layer {
  filter: $hover-filter;
}

.noFavorites {
  color: $grey-color;
  font-family: $fontSansReg;
  font-style: normal;
  font-size: 16px;
  padding: 40px;
}

/*************************** Divider ***************/
.vl-hr-invite {
  margin: 0 40px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid $grey-color-dark;
}

/*************************** invite panel ***************/

.invite-header,
.profile,
.custom-switches {
  .ant-switch {
    height: 18px;
    line-height: 18px;
    width: 32px;
    min-width: 32px;
    background-color: $grey-color-dark;

    .ant-switch-handle {
      top: 4px;
      left: 4px;
      width: 10px;
      height: 10px;
    }

    .ant-switch-handle::before {
      background-color: #FFFFFF;
    }

    &.ant-switch-checked {
      background-color: $label-color;

      .ant-switch-handle {
        left: calc(60%);
      }

      .ant-switch-handle::before {
        background-color: #FFFFFF;
      }
    }
  }

  .switch-label {
    display: inline-block;
    margin: 0 10px 0 5px;
    vertical-align: middle;
  }

  .switch-icon {
    display: inline-block;
    margin: 0 10px 0 5px;
    height: 35px;
  }
}

.invite-panel {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding-bottom: 60px;
}

.invite-header {
  margin-right: 65px;
  height: 55px;
  line-height: 55px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}

.vl-invite-tabs {
  margin: 0 40px;
  height: 40px;
  line-height: 40px;
  display: flex;
  border-top: 1px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-dark;
  cursor: pointer;
  font-size: 16px;

  div {
    flex: 1 0 auto;
    text-align: center;
    height: 100%;
    color: $grey-color-dark;

    &.vl-selected {
      background-color: $selected-background;
      color: #FFFFFF;
    }
  }
}

.vl-saveToFav {
  height: 80px;
  margin-left: 40px;
  padding-top: 20px;
}

/**************************  List **********************/

li.ant-list-item.vl-list-item {
  margin: 0 40px;
  padding: 5px 0;
  width: 420px;
  min-height: 60px;
}

@media screen and (max-width: 1024px) {
  .vl-movableMod {
    z-index: 1000;
    width: 100vw;
    height: calc(100vh - 50px);
  }

  .ant-layout-footer {
    padding: 0;

  }

  .vl-header {
    min-height: 70px;
  }

  .vl-header .vl-right-section{
    display: flex;
    align-items: center;
  }
  
  .vl-app-footer.ant-layout-footer {
    min-height: 70px !important;
    padding: 10px !important;
  }

  .vl-footer-img {
    height: 40px !important;
  }

  .vl-chat-main {
    height: calc(100vh - 320px);
  }

  .vl-chat-footer {
    bottom: 10px !important;
  }

  .vl-chat-form .vl-select-field {
    margin-left: 0 !important;
  }

  .vl-modalDivider {
    width: calc(100% - 20px);
  }

  .vl-select-field {
    width: calc(100% - 80px);
  }
}

.list-star {
  line-height: 50px;
}
.list-star-hidden {
  padding-right: 23px;
}


.profile-available {
  .ant-badge-status-default {
    background-color: #44B449;
  }
}

.profile-oncall {
  .ant-badge-status-default {
    background-color: #1990cf;
  }
}

.profile-dnd {
  .ant-badge-status-processing {
    background-color: $red-color;
  }
}

.profile-offline {
  .ant-badge-status-default {
    background-color: $grey-color-dark;
  }
}

.circle1 {
  background-color: $label-color;
  border: 1px solid $label-color;
  height: 40px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 40px;
}

.vl-circle-inner1 {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  color: $primary-color;
  line-height: 40px;
  text-align: center;
  background: $label-color;
  font-weight: bold;
  font-size: 15px;
  display: inline-block;
  margin: 5px 0;
}

.circle-inner-big {
  @extend .vl-circle-inner1;
  width: 300px;
  height: 300px;
  border-radius: 300px;
  line-height: 300px;
  font-size: 100px;
  user-select: none;

  &:hover {
    background-color: $label-color;
  }
}

.circle-inner-100 {
  @extend .vl-circle-inner1;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  line-height: 100px;
  font-size: 30px;
  cursor: pointer;
  margin-bottom: 15px;
}

.badge-style {
  display: inline-block;
  color: $grey-color;
  font-family: $fontSansReg;
  font-style: normal;
  font-size: 16px;
  margin: 0 10px;
}

.search-profile1 {
  .ant-badge-status-text {
    color: red;
  }

  .ant-badge-status-processing {
    width: $status-width;
    height: $status-width;
    margin: 12px;
  }

  .ant-badge-status-default {
    @extend .ant-badge-status-processing;
  }
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
  fill: $grey-color;

}

span.filled.anticon.anticon-star:hover {
  color: red !important;
}

.video-button1 {
  width: 25px;
}

.icon1 {
  margin-top: 10px;
}

.full-width {
  width: 100%;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
}

.vl-radio-group {
  .ant-radio-group {
    margin: 20px 40px 0;
    display: flex;
    flex-flow: row wrap;

    label {
      display: inline-block;
      margin: 0;
      flex: 1 0 auto;
      text-align: center;
      height: 100%;
    }
  }

  &.ipc-modal {
    .ant-radio-group {
      flex-flow: column wrap;
    }
    .ant-radio-wrapper {
      text-align: left;
    }
    .ant-form-item {
      //margin-bottom: 0 !important;
    }
    .ant-radio-wrapper-checked {
      color: $label-color !important;
    }
    .ant-radio-wrapper::after {
      height: 0;
    }
    .note {
      color: $white;
      font-size: 12px;
      margin: 0 0 0 25px;
    }
  }

  .ant-radio-wrapper {  
    font-family: $fontSansReg;
    font-weight: normal;
    font-size: 16px;
    color: $grey-color;
  }

  .ant-radio-wrapper.ant-radio-wrapper-checked {
    color: #44B449;
  }

  .ant-radio-inner {
    border-color: $grey-color  !important;
    background-color: $primary-color  !important;
    border-width: 2px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: $grey-color  !important;
    background-color: $primary-color  !important;
    border-width: 2px;
  }

  .ant-radio-checked .ant-radio-inner::after {
    background-color: #44B449 !important;
    //top: 2px;
    //left: 2px;
  }

  .ant-radio-inner:hover,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-wrapper:hover,
  .ant-radio-input:focus .ant-radio-inner {
    border-color: #44B449 !important;
  }
}

.vl-header-menu.ant-dropdown-menu {
  @extend .box-shadow;
  background-color: $primary-panel-color-no-transparency;
  border-radius: 5px;
  color: $grey-color  !important;
  font-weight: normal;
  font-size: 16px;
  padding: 15px 8px;
}

.vl-header-menu {

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: $grey-color;
    font-weight: normal;
    font-size: 16px;
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: transparent;
    color: $label-color;
  }
}

.ant-notification-notice {
  @extend .box-shadow;
  background-color: $primary-panel-color-no-transparency  !important;
  border-radius: 5px;
  color: $grey-color  !important;
  font-weight: normal;
  font-size: 16px;
  padding: 15px 8px;

  .ant-notification-notice-message {
    color: #FFFFFF;
  }
}

.ant-notification-topRight {
  top: 120px !important;
}

.header-modal {
  @extend .box-shadow;
  background: rgba($primary-panel-color, 0.9);
  border-radius: 10px;
  padding-bottom: 0 !important;

  .ant-modal-content {
    background-color: transparent;
  }

  .ant-modal-header {
    background-color: transparent;
    border-bottom: 0;
    padding: 0 40px;
  }

  .ant-modal-title {
    color: $label-color;
    width: 100%;
  }

  .ant-modal-close {
    right: 18px;
  }

  .ant-modal-body {
    padding: 20px 40px 40px 40px;
    color: $grey-color;
  }

  .anticon:not(.ant-select-suffix) {
    color: $label-color;

    &:hover {
      color: $label-hover;
    }
  }
}

.modal-title {
  color: $label-color;
  box-sizing: content-box !important;
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-size: 24px;
  margin-right: 25px;
  border-bottom: 1px solid $grey-color-dark;
}

.common-modal {
  @extend .header-modal;
}

.profile-edit-btn {
  &:hover {
    color: $label-color;
    filter: invert(59%) sepia(84%) saturate(1302%) hue-rotate(179deg) brightness(100%) contrast(102%);
  }
}

.profile-edit {
  color: $label-color;
  font-family: "OpenSansReg";
  font-size: 16px;
  margin: 0 10px;
}
