.vl-zoomContainer>div {
  transform: translate(0px, 0px) !important;
}

.vl-zoomContainer * {
  box-shadow: none !important;
}

.vl-zoomContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #242424;
}

#zmmtg-root {
  display: none;
}

[data-no-focus-lock="true"] {
  display: none !important;
}

.video-avatar__mute-button {
  display: none !important;
}

.meeting-info-container {
  display: none !important;
}

#wc-footer {
  height: 72px;
}

.vl-app-footer {
  height: inherit;
}

.vl-right-section,
.vl-middle-section,
.vl-left-section {
  padding-top: 5px;
}