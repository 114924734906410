@import "theme";

.video-container {
    border: 0;
    background-color: transparent;
    width: 100%;
    background-color: black;
    height: calc(100vh - 200px); // 100vh - (header + footer)
    margin-top: 0px;

    .ant-layout {
        height: 100%;
    }

    .ant-layout-header {
        height: 60px;
        padding: 0;
        margin: 0;
    }

    .ant-layout-footer {
        @extend .ant-layout-header;
    }

    .video-button {
        width: 50px;
        height: 50px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .video-button-background {
        background-color: #080A0B;
        opacity: .83;
        border-radius: 5px;
    }

    .main-video {
        height: 100%;
        max-height: 100%;
        background-color: black;
        position: relative;

        .vl-video-spin {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 200px;
            height: 200px;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        video {
            width: 100% !important;
            height: 100% !important;
            max-height: 100% !important;
        }

        .selfview-video {
            position: absolute;
            z-index: 2;
            width: auto;
            height: 113px;
            cursor: move;
        }

        .eko-video {
            @extend .selfview-video;
            border: 2px solid #216ba5;
        }

        .selfview-video-off {
            border-style: solid; 
            border-color:red;
        }

        .camera-right-layout {
            position: absolute;
            z-index: 2;
            width: 200px;
            height: 300px;
            right: 10px;
            bottom : 150px;
            display : flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    .video-buttons {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 60px;
        padding-top: 5px;
        padding-bottom: 5px;
        justify-content: center;
    }
}

.vl-screenshot-canvas {
    position: absolute;
    visibility: hidden;
    display: none;
}

.video-camera-modal {
    .ant-modal-header {
        background : none;
        color: transparent;
    }
    .ant-modal-title { 
        color : white;
    }
    .ant-modal-close {
        color : white;
    }    
    .ant-modal-content {
        background-color : rgba(0,0,0,0.3);
        border-radius: 25px;
    }

    .ant-slider-track { 
        background-color : white;        
    }
    .ant-slider-rail {
        background-color : white;
    }
    .ant-slider-handle {
        background-color : $primary-color;
        border: solid 2px $primary-color;
    }
    .menu-button {
        width: 20px;
        height: 20px;
    }
    h3 {
        color : white;
        margin : 0;
    }
    .ant-row {
        margin-bottom : 10px;
    }
}

.vl-movableDiv {
    border: 0.5px solid #c6c6c8;
}

.video-container:-webkit-fullscreen {
    width: 100vw;
    height: 100vh;
    /** etc.. */
}

.video-container:-moz-fullscreen {
    width: 100vw;
    height: 100vh;
    /** etc.. */
}

.video-container:-ms-fullscreen {
    width: 100vw;
    height: 100vh;
    /** etc.. */
}

.video-container:fullscreen {
    width: 100vw;
    height: 100vh;
    /** etc.. */
}
